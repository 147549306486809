exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-closed-beta-js": () => import("./../../../src/pages/closed-beta.js" /* webpackChunkName: "component---src-pages-closed-beta-js" */),
  "component---src-pages-features-capture-js": () => import("./../../../src/pages/features/capture.js" /* webpackChunkName: "component---src-pages-features-capture-js" */),
  "component---src-pages-features-countup-js": () => import("./../../../src/pages/features/countup.js" /* webpackChunkName: "component---src-pages-features-countup-js" */),
  "component---src-pages-features-organize-js": () => import("./../../../src/pages/features/organize.js" /* webpackChunkName: "component---src-pages-features-organize-js" */),
  "component---src-pages-features-reflection-js": () => import("./../../../src/pages/features/reflection.js" /* webpackChunkName: "component---src-pages-features-reflection-js" */),
  "component---src-pages-features-routines-js": () => import("./../../../src/pages/features/routines.js" /* webpackChunkName: "component---src-pages-features-routines-js" */),
  "component---src-pages-help-apple-2-fa-js": () => import("./../../../src/pages/help/apple-2fa.js" /* webpackChunkName: "component---src-pages-help-apple-2-fa-js" */),
  "component---src-pages-help-cancel-subscription-js": () => import("./../../../src/pages/help/cancel-subscription.js" /* webpackChunkName: "component---src-pages-help-cancel-subscription-js" */),
  "component---src-pages-help-checklists-js": () => import("./../../../src/pages/help/checklists.js" /* webpackChunkName: "component---src-pages-help-checklists-js" */),
  "component---src-pages-help-delegate-tasks-js": () => import("./../../../src/pages/help/delegate-tasks.js" /* webpackChunkName: "component---src-pages-help-delegate-tasks-js" */),
  "component---src-pages-help-helper-js": () => import("./../../../src/pages/help/helper.js" /* webpackChunkName: "component---src-pages-help-helper-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-help-keyboard-shortcuts-js": () => import("./../../../src/pages/help/keyboard-shortcuts.js" /* webpackChunkName: "component---src-pages-help-keyboard-shortcuts-js" */),
  "component---src-pages-help-list-foundations-js": () => import("./../../../src/pages/help/list-foundations.js" /* webpackChunkName: "component---src-pages-help-list-foundations-js" */),
  "component---src-pages-help-lists-js": () => import("./../../../src/pages/help/lists.js" /* webpackChunkName: "component---src-pages-help-lists-js" */),
  "component---src-pages-help-notifications-setup-js": () => import("./../../../src/pages/help/notifications-setup.js" /* webpackChunkName: "component---src-pages-help-notifications-setup-js" */),
  "component---src-pages-help-payment-details-js": () => import("./../../../src/pages/help/payment-details.js" /* webpackChunkName: "component---src-pages-help-payment-details-js" */),
  "component---src-pages-help-payment-error-store-js": () => import("./../../../src/pages/help/payment-error-store.js" /* webpackChunkName: "component---src-pages-help-payment-error-store-js" */),
  "component---src-pages-help-payment-js": () => import("./../../../src/pages/help/payment.js" /* webpackChunkName: "component---src-pages-help-payment-js" */),
  "component---src-pages-help-platforms-js": () => import("./../../../src/pages/help/platforms.js" /* webpackChunkName: "component---src-pages-help-platforms-js" */),
  "component---src-pages-help-reflection-js": () => import("./../../../src/pages/help/reflection.js" /* webpackChunkName: "component---src-pages-help-reflection-js" */),
  "component---src-pages-help-reviews-js": () => import("./../../../src/pages/help/reviews.js" /* webpackChunkName: "component---src-pages-help-reviews-js" */),
  "component---src-pages-help-shared-lists-js": () => import("./../../../src/pages/help/shared-lists.js" /* webpackChunkName: "component---src-pages-help-shared-lists-js" */),
  "component---src-pages-help-siri-js": () => import("./../../../src/pages/help/siri.js" /* webpackChunkName: "component---src-pages-help-siri-js" */),
  "component---src-pages-help-uvp-js": () => import("./../../../src/pages/help/uvp.js" /* webpackChunkName: "component---src-pages-help-uvp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-s-js": () => import("./../../../src/pages/s.js" /* webpackChunkName: "component---src-pages-s-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

