/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const smoothscroll = require("smoothscroll-polyfill")

exports.onClientEntry = () => {
  smoothscroll.polyfill()
}
